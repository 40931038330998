import { Model3dActions } from "../actions/model3dActions";

const initialState = {
    canvasBackgroundColor: "#9f9d9d",
    transform: {
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: { x: 1, y: 1, z: 1 },
    },
    directionLightProp: {
        position: { x: 0, y: 6, z: 0 },
        intensity: 5,
        color: "#ffffff",
        shadowRadius: 1,
        environment: {
            label: "None",
            value: "none",
        },
        environmentExposure: 1,
    },
    ambientLightProp: {
        color: "#ffffff",
    },
    isMobileExportPanelHidden: true,
    modelUrl: "",
};

const model3dReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case Model3dActions.SetPosition:
            return {
                ...state,
                transform: {
                    ...state.transform,
                    position: payload,
                },
            };

        case Model3dActions.SetRotation:
            return {
                ...state,
                transform: {
                    ...state.transform,
                    rotation: payload,
                },
            };

        case Model3dActions.SetScale:
            return {
                ...state,
                transform: {
                    ...state.transform,
                    scale: payload,
                },
            };

        case Model3dActions.SetDirectionLightPosition:
            return {
                ...state,
                directionLightProp: {
                    ...state.directionLightProp,
                    position: payload,
                },
            };
        case Model3dActions.SetDirectionLightIntensity:
            return {
                ...state,
                directionLightProp: {
                    ...state.directionLightProp,
                    intensity: payload,
                },
            };
        case Model3dActions.SetDirectionLightColor:
            return {
                ...state,
                directionLightProp: {
                    ...state.directionLightProp,
                    color: payload,
                },
            };
        case Model3dActions.SetDirectionLightShadowRadius:
            return {
                ...state,
                directionLightProp: {
                    ...state.directionLightProp,
                    shadowRadius: payload,
                },
            };
        case Model3dActions.SetEnvironmentFilesValue:
            return {
                ...state,
                directionLightProp: {
                    ...state.directionLightProp,
                    environment: payload,
                },
            };
        case Model3dActions.SetEnvironmentExposure:
            return {
                ...state,
                directionLightProp: {
                    ...state.directionLightProp,
                    environmentExposure: payload,
                },
            };
        case Model3dActions.SetCanvasColor:
            return {
                ...state,
                canvasBackgroundColor: payload,
            };

        case Model3dActions.SetAmbientLightColor:
            return {
                ...state,
                ambientLightProp: {
                    color: payload,
                },
            };
        case Model3dActions.ResetTransform:
            return {
                ...state,
                transform: payload,
            };
        case Model3dActions.ResetLighting:
            return {
                ...state,
                directionLightProp: payload,
            };
        case Model3dActions.SetIsMobileExportPanelHidden:
            return {
                ...state,
                isMobileExportPanelHidden: payload,
            };
        case Model3dActions.SetModelUrl:
            return {
                ...state,
                modelUrl: payload,
            };
        default:
            return state; // Ensure to return the current state for any other action types
    }
};

export default model3dReducer;
