import React from "react";
import styled from "styled-components";

const StyledPath = styled.path`
    stroke: ${({ active }) => (active ? "url(#paint0_linear)" : "#fff")};
`;
const BackIcon = ({ disabled, ...props }) => {
    const styles = {
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
    };

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#595959"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            {...styles}
        >
            <StyledPath
                d="M15 18L9 12L15 6"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                active={props.active}
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="10.5263"
                    y1="5.12195"
                    x2="16.474"
                    y2="6.06808"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF4040" />
                    <stop offset="1" stopColor="#A20BD8" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default BackIcon;
