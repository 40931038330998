import React from "react";
import styled from "styled-components";
import { btnStyleName } from "../constants/theme.constants";

const BaseButton = styled.button`
    border-radius: var(--border-radius-250);
    color: var(--white);
    font-family: var(--body-font);
    font-style: normal;
    overflow: hidden;
    position: relative;
    display: ${(props) => (props.truncateText ? "unset" : "flex")};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    width: ${(props) => props.width};
    margin-left: ${({ marginLeft }) => marginLeft};
    margin-right: ${({ marginRight }) => marginRight};
    margin-top: ${({ marginTop }) => marginTop};
    white-space: ${({ truncateText }) => truncateText && "nowrap"};
    text-overflow: ${({ truncateText }) => truncateText && "ellipsis"};
    letter-spacing: ${({ size }) =>
        size === "small" ? "var(--letter-spacing-15)" : "var(--letter-spacing-1)"};
    line-height: 120%;
    padding: ${({ size }) => {
        switch (size) {
            case "default":
                return "0 24px";
            case "small":
                return "0 16px";
            default:
                return "0 40px";
        }
    }};
    height: ${({ size, height }) => {
        let finalHeight;
        switch (size) {
            case "default":
                finalHeight = "48px";
                break;
            case "small":
                finalHeight = "40px";
                break;
            default:
                finalHeight = "56px";
                break;
        }
        return height || finalHeight;
    }};
    font-size: ${({ size }) => {
        switch (size) {
            case "default":
                return "var(--button)";
            case "small":
                return "var(--button-small)";
            default:
                return "var(--button)";
        }
    }};

    @media (max-width: 767px) {
        font-size: var(--body-3-d);
        font-size: var(--button-small);
        height: 40px;
        padding: 0 16px;
    }
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`;

const PrimaryButton = styled(BaseButton)`
    background: var(--primary-50);

    :hover {
        background: var(--primary-60);
    }
    :active {
        color: var(--primary-30);
        background: var(--primary-60);

        svg path {
            fill: var(--primary-30);
        }
    }
`;

const SecondaryButton = styled(BaseButton)`
    background: transparent;
    border: 1px solid var(--gray-40);
    color: var(--primary-60);

    :hover {
        background: ${(props) => (props.hoverTransparent ? "" : "var(--primary-20)")};
        border: 1px solid var(--gray-40);
    }
    :active {
        color: var(--primary-70);
        background: var(--primary-30);

        svg path {
            fill: var(--primary-70);
        }
    }
`;

const TertiaryButton = styled(BaseButton)`
    /* background: rgba(255, 255, 255, 0); */
    background: var(--tertiary);
    border: 1px solid var(--border-light);
    :hover {
        background: var(--light-bg-1-hover);
        border: 1px solid var(--dark-600);
    }
`;

const DangerButton = styled(BaseButton)`
    background: var(--error-50);
    :hover {
        background: var(--error-80);
    }
    :active {
        color: var(--gray-40);
        background: var(--error-80);
    }
    :disabled {
        opacity: 0.5;
    }
`;

const TextButton = styled(BaseButton)`
    background-color: transparent;
`;

const OutLineButton = styled(BaseButton)`
    background: transparent;
    border: ${(props) => `1px solid var(${btnStyleName?.[props.mode]?.bg})`};
    color: ${(props) => `var(${btnStyleName?.[props.mode]?.bg})`};
    :hover {
        background: ${(props) => `var(${btnStyleName?.[props.mode]?.bg})`};
        color: ${(props) => (props.hoverClr ? `var(${props.hoverClr})` : "inherit")};
    }
`;

const normalBtnCase = ({
    children,
    mode, // cta, primary, secondary, tertiary, danger
    size = "default", // default, small, large
    ...props
}) => {
    switch (mode) {
        case "cta":
            return (
                <PrimaryButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </PrimaryButton>
            );
        case "primary":
            return (
                <PrimaryButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </PrimaryButton>
            );
        case "secondary":
            return (
                <SecondaryButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </SecondaryButton>
            );
        case "tertiary":
            return (
                <TertiaryButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </TertiaryButton>
            );
        case "danger":
            return (
                <DangerButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </DangerButton>
            );
        case "text":
            return (
                <TextButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </TextButton>
            );
        default:
            return (
                <PrimaryButton data-testid="sample-btn" size={size} {...props}>
                    {children}
                </PrimaryButton>
            );
    }
};

const outlineSwitchCase = ({
    children,
    mode, // cta, primary, secondary, tertiary, danger
    size = "default", // default, small, large
    ...props
}) => {
    return (
        <OutLineButton data-testid="sample-btn" size={size} {...props} mode={mode}>
            {children}
        </OutLineButton>
    );
};

const Button = ({
    variant = "normal", // normal, outline
    ...props
}) => {
    switch (variant) {
        case "normal":
            return normalBtnCase(props);

        case "outline":
            return outlineSwitchCase(props);

        default:
            return normalBtnCase(props);
    }
};

export default Button;
