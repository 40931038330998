import environment from "../../environment";

const apiUrls = {
    deleteAccount: `${environment.NEJI_MAIN_URL}/v1.0/profile`,
    registerUser: `${environment.NEJI_MAIN_URL}/v1.0/authentication/register`,
    loginUser: `${environment.NEJI_MAIN_URL}/v1.0/authentication/login/password`,
    setCookieToHinata: `${environment.NEJI_MAIN_URL}/v1.0/authentication/token`,
    forgotPassword: `${environment.NEJI_MAIN_URL}/v1.0/authentication/forgot-password`,
    resetPassword: `${environment.NEJI_MAIN_URL}/v1.0/authentication/set-password`,
    verifyEmail: `${environment.NEJI_MAIN_URL}/v1.0/authentication/register/complete`,
    googleLogin: `${environment.NEJI_MAIN_URL}/v1.0/authentication/login/google`,
    logoutUser: `${environment.NEJI_MAIN_URL}/v1.0/authentication/logout`,
    currentUserSession: `${environment.NEJI_MAIN_URL}/v1.0/session`,
    allUserSessions: `${environment.NEJI_MAIN_URL}/v1.0/session/list`,
    profileDetails: `${environment.NEJI_MAIN_URL}/v1.0/profile/detail`,
    consent: `${environment.NEJI_MAIN_URL}/v1.0/consent`,
    updateEmailInit: `${environment.NEJI_MAIN_URL}/v1.0/profile/update-email-init`,
    updateEmailComplete: `${environment.NEJI_MAIN_URL}/v1.0/profile/update-email-complete`,
    updatePassword: `${environment.NEJI_MAIN_URL}/v1.0/profile/update-password`,
    searchUsers: `${environment.NEJI_MAIN_URL}/v1.0/org/:orgId/search?email=:email`,
    googleOAuthLogin: `${environment.NEJI_WEBHOOK_URL}/v1.0/authentication/google`,
    appleOAuthLogin: `${environment.NEJI_WEBHOOK_URL}/v1.0/authentication/apple`,
    updateMeta: `${environment.NEJI_MAIN_URL}/v1.0/profile/meta`,
    googleOneTapLogin: `${environment.NEJI_WEBHOOK_URL}/v1.0/authentication/google/one-tap`,
    createOrganization: `${environment.HIRUZEN_MAIN_URL}/v1.0/`,
    getOrganizationDetails: `${environment.HIRUZEN_MAIN_URL}/v1.0/`,
    getOrganizationDetailsById: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/detail`,
    updateOrganizationDetailsById: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/detail`,
    getOrganizationPreference: `${environment.HIRUZEN_MAIN_URL}/v1.0/`,
    getOrganizationsForUser: `${environment.HIRUZEN_MAIN_URL}/v1.0/all`,
    createApp: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/apps`,
    getApps: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/apps`,
    selectApps: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/apps/:appId/select`,
    getAppDetails: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    updateApp: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    deleteApp: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    createTeamMember: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/team/`,
    getTeam: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/team/?userId=:userId&page=:page&pageSize=:pageSize`,
    getTeamMember: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    updateTeamMember: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    deleteTeamMember: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    getRoles: `${environment.HIRUZEN_MAIN_URL}/v1.0/role`,
    getDomains: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/domains/?searchQuery=:searchQuery&page=:page&pageSize=:pageSize`,
    getDomainDetails: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/domains/:domainId`,
    createDomain: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/domains`,
    deleteDomain: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/domains/:domainId`,
    getWorkflow: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/integration/workflows`,
    deleteIntegration: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/integration/:slug`,
    getIntegration: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/integration`,
    getIntegrationsList: `${environment.HIRUZEN_MAIN_URL}/v1.0/org/:orgId/integration/list`,

    search: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/search`,
    explore: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/explore`,
    exploreFolder: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/explore/folders`,
    directUpload: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/upload/direct`,
    signedUrlUpload: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/upload/signed-url`,
    urlsUpload: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/upload/urls`,
    createFolder: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/folders`,
    filesUrl: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/files`,
    transformationsUrl: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/files/transformations`,
    foldersUrl: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/folders`,
    ancestors: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/folders/:folderId/ancestors`,
    download: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/proxy/download`,
    downloadOpen: `${environment.CHOJI_PNL_URL}/v1.0/proxy/download`,
    plugins: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/playground/plugins`,
    playgroundDefault: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/playground/default`,
    credentials: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/credentials`,
    presets: `${environment.CHOJI_PNL_URL}/v1.0/org/:orgId/presets`,
    updateFile: `${environment.CHOJI_PNL_URL}/v1.0/files/:fileId`,

    webhooks: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/webhook-configs`,
    webhook: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/webhook-configs/:webhookId`,
    testWebhook: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/webhook-configs/test`,
    webhookEvents: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/events`,
    webhookLogs: `${environment.GAARA_MAIN_URL}/v1.0/org/:orgId/logs`,

    insights: `${environment.ITACHI_PNL_URL}/v1.0/org/:orgId/insights`,

    zones: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/zones`,
    zone: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/zones/:zoneId`,
    zoneQualityOptions: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/zones/quality-options`,
    datasourceSchemas: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources/schemas`,
    datasourceSchema: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources/schemas/:datasourceType`,
    datasources: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources`,
    datasource: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources/:datasourceId`,
    datasourceAssets: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/datasources/:datasourceId/assets`,
    dropboxDatasourceOAuthRedirectUri: `${environment.JIRAIYA_WEBHOOK_URL}/v1.0/datasources/dropbox/auth`,
    purgeCdns: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/cdns/purge`,
    purgeCdnsAll: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/cdns/purge/all`,
    jobs: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/jobs`,
    activeJobCount: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/jobs/state/active`,
    bulkDownload: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/jobs/download/bulk`,
    bulkValidate: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/jobs/validate/bulk`,
    transformationDetail: `${environment.JIRAIYA_CDN_DOMAIN}/context`,
    signedURLs: `${environment.JIRAIYA_PNL_URL}/v1.0/org/:orgId/signed-urls`,

    transformationList: `${environment.ITACHI_PNL_URL}/v1.0/org/:orgId/transformations/list`,

    subscriptions: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions`,
    activeSubscription: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions/active-subscription`,
    cancelSubscription: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions/cancel-subscription`,
    upcomingSubscription: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions/upcoming-subscription`,
    plan: `${environment.RUPIKA_PNL_URL}/v1.0/plans/:id`,
    plans: `${environment.RUPIKA_PNL_URL}/v1.0/plans`,
    addOn: `${environment.RUPIKA_PNL_URL}/v1.0/addons/:id`,
    addOns: `${environment.RUPIKA_PNL_URL}/v1.0/addons`,
    cards: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/cards`,
    updateCards: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/cards/:paymentMethodId`,
    initCard: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/cards/init`,
    invoices: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/invoices`,
    pgInvoice: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/invoices/pgId/:pgId`,
    pgInvoiceOpen: `${environment.RUPIKA_PNL_URL}/v1.0/invoices/pgId/:pgId`,
    subscriptionUsage: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/usage/subscription`,
    addonsUsage: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/usage/addons`,
    totalUsage: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/usage`,
    refunds: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/refunds`,
    payments: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/payments`,
    customerAddOns: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/customerAddons`,
    billingAddress: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/customers/billingAddress`,
    currency: `${environment.RUPIKA_PNL_URL}/v1.0/currency`,
    getChangeActionType: `${environment.RUPIKA_PNL_URL}/v1.0/org/:orgId/subscriptions/change-action-type`,

    deleteBySku: `${environment.HIDAN_PNL_URL}/v1.0/org/:orgId/skus/:skuId`,
    disableBySku: `${environment.HIDAN_PNL_URL}/v1.0/org/:orgId/skus/:skuId`,
    getPaginatedSkus: `${environment.HIDAN_PNL_URL}/v1.0/org/:orgId/skus`,
    createGlamarSku: `${environment.HIDAN_PNL_URL}/v1.0/org/:orgId/skus`,
    updateGlamarSku: `${environment.HIDAN_PNL_URL}/v1.0/org/:orgId/skus/:skuId`,
    getSkuById: `${environment.HIDAN_PNL_URL}/v1.0/org/:orgId/skus/:skuId`,
    bulkDeleteSkus: `${environment.HIDAN_PNL_URL}/v1.0/org/:orgId/skus/delete`,
    modelConfig: `${environment.HIDAN_PNL_URL}/v1.0/org/:orgId/models`,
    toggleModelStatus: `${environment.HIDAN_PNL_URL}/v1.0/org/:orgId/models/:modelId`,
    deleteModel: `${environment.HIDAN_PNL_URL}/v1.0/org/:orgId/models/:modelId`,
    auditLogs: `${environment.SHIRAYUKI_PNL_URL}/v1.0/org/:orgId/logs`,
    auditLog: `${environment.SHIRAYUKI_PNL_URL}/v1.0/org/:orgId/logs/:logId`,

    // KONOHOMARU URLS
    getAllModels: `${environment.KONOHAMARU_MAIN_DOMAIN}/v1.0/org/:orgId/capture`,
    createCapture: `${environment.KONOHAMARU_MAIN_DOMAIN}/v1.0/org/:orgId/capture`,
    createCaptureByVideo: `${environment.KONOHAMARU_MAIN_DOMAIN}/v1.0/org/:orgId/capture/video3d`,

    // CMS URLs
    privacyPolicy: `${environment.BORUTO_PUBLIC_URL}/api/privacy/content`,

    // GLAMAR_V2 URLs
    getPaginatedProducts: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/skus`,
    deleteSkuById: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/skus/:skuId`,
    deleteAll: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/skus/delete`,
    getOverview: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/overview`,
    deleteMultipleSkus: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/skus/delete`,
    getModelConfigData: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/category/:categoryName/config/`,
    getModelConfigOptions: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/category/:categoryName/config/schema`,
    updateModelConfig: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/category/:category/config/`,
    createGlamarv2Sku: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/skus`,
    updateGlamarv2Sku: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/skus/:skuId`,
    saveAsDraft: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/skus`,
    getGlamarv2SkuById: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/skus/:skuId`,
    initConfig: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/init`,
    getPatterns: `${environment.HIDAN_PNL_URL}/v2.0/patterns/category/:category/subcategory/:subcategory`,
    getPatternById: `${environment.HIDAN_PNL_URL}/v2.0/patterns/:patternId`,
    createBulkSku: `${environment.HIDAN_PNL_URL}/v2.0/org/:orgId/skus/bulk-upload`,
};

export default apiUrls;
