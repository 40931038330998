import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as AngleDown } from "../assets/svgs/Angle Down.svg";

const Wrapper = styled.div`
    width: ${(props) => (props.width ? props.width : "")};
    align-self: ${(props) => (props.align ? props.align : "")};
    font-size: var(--body-2-d);

    @media (max-width: 768px) {
        font-size: var(--body-2-m);
    }

    .react-select__control {
        border-radius: var(--border-radius-8);
        border: 1px solid var(--gray-40);
        background: var(--surface-0);
        box-shadow: none;
        cursor: pointer;
        min-height: 32px;

        &--menu-is-open {
            .react-select__dropdown-indicator {
                transform: rotate(180deg);
            }

            border-color: var(--primary-60) !important;
        }

        :hover {
            border-color: rgba(203, 205, 254, 0.22);
        }
    }

    .react-select__single-value {
        color: var(--text-subdued-1);
    }

    .react-select__indicator-separator {
        display: none;
    }

    .react-select__dropdown-indicator {
        display: block;
        background-image: ${(props) => props.showArrow && `url(${AngleDown})`};
        background-repeat: no-repeat;
        background-size: contain;
    }

    .react-select__menu {
        border: none;
        border-radius: 8px;
        padding: 6px;
        border-radius: var(--border-radius-12);
        background-color: var(--surface-0);
        box-shadow: 0px 24px 32px -12px rgba(60, 42, 152, 0.04),
            0px 12px 12px -6px rgba(60, 42, 152, 0.04), 0px 6px 6px -3px rgba(60, 42, 152, 0.04),
            0px 3px 3px -1.5px rgba(60, 42, 152, 0.04), 0px 1px 1px -0.5px rgba(60, 42, 152, 0.02),
            0px 0px 0px 1px rgba(60, 42, 152, 0.02);
    }

    .react-select__option {
        display: block !important;
        background-color: transparent;
        height: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 12 px;
        color: var(--text-default);
        border-radius: var(--border-radius-8);

        :hover {
            background: var(--surface-20);
        }

        &--is-selected {
            background: var(--surface-20);
            cursor: default;
        }
    }

    .react-select__input {
        font-family: var(--body-font);
        color: var(--surface-0);
    }

    .react-select__placeholder {
        font-family: var(--body-font);
        font-style: normal;
        font-weight: normal;
        line-height: 21px;
        letter-spacing: 0.5px;
        color: var(--dark-500);
    }

    .react-select--is-disabled {
        .react-select__control {
            border-color: var(--disabled);
            opacity: 0.5;
        }
        .react-select__placeholder {
            color: var(--disabled);
        }
        .react-select__dropdown-indicator {
            svg {
                fill: var(--disabled);
            }
        }
    }
`;

const CustomDropdown = ({
    className = "react-select-container",
    classNamePrefix = "react-select",
    options,
    onChange,
    isSearchable = false,
    placeholder = "Select",
    wrapperWidth,
    wrapperHeight,
    wrapperAlign,
    showArrow = true,
    hideIndicator,
    ...rest
}) => {
    return (
        <Wrapper
            width={wrapperWidth}
            height={wrapperHeight}
            align={wrapperAlign}
            showArrow={showArrow}
            hideIndicator={hideIndicator}
            {...rest}
        >
            <WrapperComp
                className={className}
                classNamePrefix={classNamePrefix}
                options={options}
                onChange={onChange}
                isSearchable={isSearchable}
                placeholder={placeholder}
                menuPlacement="auto"
                menuPosition="absolute"
                {...rest}
            />
        </Wrapper>
    );
};

export default CustomDropdown;

const colourStyles = {
    option: (styles) => ({
        ...styles,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "100%",
        overflow: "hidden",
    }),
};

function WrapperComp({ className, onChange, ...rest }) {
    const [isFileTypeOpen, setIsFileTypeOpen] = useState(false);
    const { isReportIssueClicked } = useSelector((state) => state.userAgentDetails);
    const selectRef = useRef(null);

    const menuOpen = () => {
        setIsFileTypeOpen(true);
    };

    useEffect(() => {
        setIsFileTypeOpen(isFileTypeOpen && isReportIssueClicked);
    }, [isReportIssueClicked]);

    const onChangeHandler = (e) => {
        onChange(e);
        setIsFileTypeOpen(false);
    };

    return (
        <Select
            innerRef={selectRef}
            onChange={onChangeHandler}
            className={className}
            onMenuOpen={menuOpen}
            menuIsOpen={isFileTypeOpen}
            onMenuClose={() => {
                if (!isReportIssueClicked) {
                    setIsFileTypeOpen(false);
                }
            }}
            styles={colourStyles}
            isDisabled={rest?.disabled}
            {...rest}
        />
    );
}
